<script>
import { Line } from 'vue-chartjs'
import moment from 'moment'

export default {
    extends: Line,
    data() {
        return{
            options: {
                layout: {
                    padding: {
                        top: 2
                    }
                },
                maintainAspectRatio: false,
                scaleShowVerticalLines: false,
                responsive: true,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            display: false,
                            padding: 0,
                            mirror: true,
                        },
                        gridLines: {
                            drawOnChartArea: false,
                            drawTicks: false,
                            display: false,
                            drawBorder: false,
                            color: '#F4F3F6',
                            zeroLineColor: '#F4F3F6'
                        },
                    }],
                    xAxes: [{
                        ticks: {
                            display: false,
                            padding: 0,
                            mirror: true,
                        },
                        gridLines: {
                            drawOnChartArea: false,
                            drawTicks: false,
                            display: false,
                            drawBorder: false,
                            color: '#F4F3F6',
                            zeroLineColor: '#F4F3F6'
                        },
                    }]
                },
                tooltips: {
                    enabled: false,
                }
            },
        }
    },
    props: {
        records: {
            type: Array,
            default: () => []
        },
        reference: {
            type: Number,
            default: 0
        }
    },
    mounted () {
        var ctx = document.getElementById('line-chart').getContext("2d");
        this.gradientFill = ctx.createLinearGradient(0, 0, 0, 80);
        if(this.records.map(ele => ele.data).slice(-1)[0] >= this.records.map(ele => ele.data)[0]){
            this.gradientFill.addColorStop(0, "rgba(222, 255, 249, 1)");
            this.gradientFill.addColorStop(1, "rgba(222, 255, 249, 0)");
        }else{
            this.gradientFill.addColorStop(0, "rgba(255, 243, 244, 1)");
            this.gradientFill.addColorStop(1, "rgba(255, 243, 244, 0)");
        }
        this.chartData.datasets[0].backgroundColor = this.gradientFill;
        this.renderChart(this.chartData, this.options)
    }
    ,watch: {
        records: function(){
            var ctx = document.getElementById('line-chart').getContext("2d");
            this.gradientFill = ctx.createLinearGradient(0, 0, 0, 80);
            if(this.records.map(ele => ele.data).slice(-1)[0] >= this.records.map(ele => ele.data)[0]){
                this.gradientFill.addColorStop(0, "rgba(222, 255, 249, 1)");
                this.gradientFill.addColorStop(1, "rgba(222, 255, 249, 0)");
            }else{
                this.gradientFill.addColorStop(0, "rgba(255, 243, 244, 1)");
                this.gradientFill.addColorStop(1, "rgba(255, 243, 244, 0)");
            }
            this.chartData.datasets[0].backgroundColor = this.gradientFill;
            this.renderChart(this.chartData, this.options)
        }
    },
    computed: {
        chartData(){
            return ({
                labels: this.records.map(ele => moment(ele.label).format('DD MMM')),
                datasets: [{
                    label: '',
                    data: this.records.map(ele => ele.data),
                    fill: 'start',
                    lineTension: 0.2,
                    borderColor: (this.records.map(ele => ele.data).slice(-1)[0] >= this.records.map(ele => ele.data)[0]) ? '#84DCCC' : '#FF545A',
                    borderWidth: 2,
                    pointBackgroundColor: 'transparent',
                    pointBorderColor: 'transparent',
                }/*, {
                    label: '',
                    data: this.records.map(() => this.reference),
                    fill: 'none',
                    borderColor: (this.records.map(ele => ele.data).slice(-1)[0] >= this.records.map(ele => ele.data).slice(-2)[0]) ? '#84DCCC' : '#FF545A',
                    borderWidth: 1,
                    pointBackgroundColor: 'transparent',
                    pointBorderColor: 'transparent',
                    borderDash: [5,5]
                }*/]
            })
        },
    }
}
</script>
