<template>
    <div class="db-modal add-portfolio">
        <div class="content">
            <div class="wrapper">
                <h3>Add new portfolio</h3>
                <div class="portfolio-form">
                    <InputUnit title="Portfolio Name" type="text" placeholder="portfolio name..." v-model="form.product_name" :hasBtn="false" />
                    <InputUnit title="Standard" type="select" :selectOptions="currencyOptions" v-model="form.standard"/>
                    <InputUnit title="Status" type="select" :selectOptions="statusOptions" v-model="form.status" :minVal="0" :hasBtn="false" />
                    <InputUnit title="Initial Fund" type="number" v-model="form.init_fund" :hasBtn="false" />
                    <InputUnit title="Records" type="number" v-model="form.init_fund" :hasBtn="false" />
                    <InputUnit class="full" title="Description (Optional)" type="textarea" v-model="form.note" />
                </div>
            </div>
            <div class="btn-container">
                <button class="blue" @click="closeModal()">Cancel</button>
                <button class="light" @click="handleCreate()">Create</button>
            </div>
        </div>
    </div>
</template>

<script>
import InputUnit from '@/components/InputUnit'
import {apiAddPortfolio} from '@/apis/pro/portfolios.js'
import $ from 'jquery'

export default {
    name: 'AddPortfolio',
    components: {
        InputUnit,
    },
    data (){
        return {
            statusOptions: [{
                title: "Operating",
                value: "1",
            }, {
                title: "Settled",
                value: "0",
            }],
            form: {
                product_name: '',
                standard: 'USDT',
                init_fund: '0.0',
                status: 1,
                note: '',
            },
            currencyOptions: [{
                title: 'USDT',
                value: 'USDT'
            }, {
                title: 'BTC',
                value: 'BTC'
            }, {
                title: 'ETH',
                value: 'ETH'
            }]
        }
    },
    props: {
    },
    methods: {
        openModal(){ // usd, usdt
            $('.db-modal.add-portfolio').fadeIn(300).css('display', 'flex');
        },
        closeModal(){
            $('.db-modal.add-portfolio').fadeOut(300);
        },
        validation(){
            if(this.form.product_name == ''){
                this.$toast.error("Please enter a product name", {
                    timeout: 2000
                });
                return false;
            }else if(this.form.standard == ''){
                this.$toast.error("Please select a standard", {
                    timeout: 2000
                });
                return false;
            }else if(this.form.init_fund == ''){
                this.$toast.error("Inital fund can't be empty.", {
                    timeout: 2000
                });
                return false;
            }else{
                return true;
            }
        },
        handleCreate(){
            if(this.validation()){
                apiAddPortfolio(this.form).then(res => {
                    if(res.data.result){
                        this.$toast.success('Portfolio Created!', {
                            timeout: 2000
                        });
                        location.reload()
                    }else{
                        this.$toast.error(res.data.err_msg, {
                            timeout: 2000
                        });
                    }
                })
            }
        }
    },
    mounted() {
        $('body').on('click', '.db-modal > .content', function(e){
            e.stopPropagation();
        })
    }
}
</script>