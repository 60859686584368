<template>
    <div class="global-switcher">
        <div v-for="(ele, ind) in options" :key="ind" :class="selected === ele.value ? `active` : ``" @click="selected = ele.value" v-html="ele.title" ></div>
    </div>
</template>

<script>

export default {
    name: 'Switcher',
    data (){
        return {
        }
    },
    props: {
        options:{
            type: Array,
            default: () => []
        },
         value: {
            type: String,
            default: ''
         }
    },
    computed: {
        selected: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    }
}
</script>